import { useTranslation } from 'react-i18next';

// CSS
import './HappInCo.css';

// MUI
import { Grid, Button } from '@mui/material';
import { SendRounded as SendIcon } from '@mui/icons-material';

// Services
import { magentaColor, greenColor, blueColor } from 'services/constants';
import { useMediaQueries } from 'services/hooks';

// Components
import Layout from 'components/Layout';

// Assets
import HappincoLogo from 'assets/images/happinco/happinco.png';
import IntroductionVideo from 'assets/videos/happinco-presentation.mp4';
import ApprenantsImg from 'assets/images/happinco/apprenants.jpg';
import EnseignantsImg from 'assets/images/happinco/enseignants.jpg';
import EntreprisesImg from 'assets/images/happinco/entreprises.jpg';
import AccompagnementImg from 'assets/images/happinco/accompagnement.jpg';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HAPP'IN CO --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function HappInCo() {

    const { t } = useTranslation();
    const { mediaSM, mediaDesktop } = useMediaQueries();

    return (
        <>
            {/* -------------------- LOGO & TITLE -------------------- */}
            <Layout containerProps={{ backgroundColor: 'rgb(235, 235, 235)' }}>
                <div
                    id="HappInCo-section"
                    className="title-container"
                >
                    <img
                        alt="Logo Happy in Company"
                        src={HappincoLogo}
                        loading="eager"
                        width={445}
                        height={135}
                        className="HappInCo-logo"
                    />
                    <h1>{t('happinco_translations.1')}</h1>
                </div>
            </Layout>
            <Layout>
                {/* -------------------- INTRO -------------------- */}
                <div style={{ padding: 24, textAlign: 'justify' }}>
                    <div>
                        {t('happinco_translations.3')}
                    </div>
                    <div style={{ marginTop: 24 }}>
                        {t('happinco_translations.5')}
                    </div>
                </div>
                {/* -------------------- VIDEO -------------------- */}
                <div style={{ padding: '0px 24px', textAlign: 'center' }}>
                    <video
                        src={IntroductionVideo}
                        autoPlay={mediaDesktop}
                        loop={mediaDesktop}
                        controls
                        muted
                        className="HappInCo-video"
                    />
                </div>
                {/* -------------------- FONCTIONNALITIES -------------------- */}
                <div style={{ padding: 24, color: 'gray' }}>
                    <h1>{t('happinco_translations.6')}</h1>
                </div>
                <Grid
                    container alignItems="center"
                    style={{ padding: 12 }}
                >
                    <Grid
                        item xs={12} md={6}
                        style={{ padding: 12 }}
                    >
                        <div
                            className="HappInCo-image"
                        />
                    </Grid>
                    <Grid
                        item xs={12} md={6}
                        style={{ padding: 12 }}
                    >
                        <ul className="HappInCo-step">
                            <li>{t('happinco_translations.7')}</li>
                            <li>{t('happinco_translations.8')}</li>
                            <li>{t('happinco_translations.9')}</li>
                            <li>{t('happinco_translations.10')}</li>
                        </ul>
                    </Grid>
                </Grid>
                <div style={{ padding: '0px 24px' }}>
                    <Button
                        href="mailto:contact@discovia.fr"
                        variant="contained" color="primary"
                        endIcon={<SendIcon />}
                    >
                        {t('happinco_translations.4')}
                    </Button>
                </div>
                {/* -------------------- VALEUR AJOUTEE -------------------- */}
                <div style={{ padding: 24, color: 'gray', marginTop: 24 }}>
                    <h1>{t('happinco_translations.11')}</h1>
                </div>
                {/* -------------------- APPRENANTS -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ padding: 24 }}
                >
                    <img
                        alt="Apprenants, apprentis et alternants"
                        src={ApprenantsImg}
                        width={300}
                        height={200}
                        loading="lazy"
                    />
                    <Grid
                        item xs={12} sm
                        style={{ marginLeft: mediaSM ? 24 : undefined, marginTop : mediaSM ? undefined : 6 }}
                    >
                        <div style={{ color: '#f5b200' }}>
                            {t('happinco_translations.12')}
                        </div>
                        <ul className="HappInCo-ul">
                            <li>{t('happinco_translations.13')}</li>
                            <li>{t('happinco_translations.14')}</li>
                            <li>{t('happinco_translations.15')}</li>
                        </ul>
                    </Grid>
                </Grid>
                {/* -------------------- ENSEIGNANTS -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ padding: 24 }}
                >
                    <img
                        alt="Enseignants, tuteurs, maîtres d’apprentissage"
                        src={EnseignantsImg}
                        width={300}
                        height={200}
                        loading="lazy"
                    />
                    <Grid
                        item xs={12} sm
                        style={{ marginLeft: mediaSM ? 24 : undefined, marginTop : mediaSM ? undefined : 6 }}
                    >
                        <div style={{ color: magentaColor }}>
                            {t('happinco_translations.16')}
                        </div>
                        <ul className="HappInCo-ul">
                            <li>{t('happinco_translations.17')}</li>
                            <li>{t('happinco_translations.18')}</li>
                            <li>{t('happinco_translations.19')}</li>
                        </ul>
                    </Grid>
                </Grid>
                {/* -------------------- ENTREPRISES -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ padding: 24 }}
                >
                    <img
                        alt="Entreprises apprenantes"
                        src={EntreprisesImg}
                        width={300}
                        height={200}
                        loading="lazy"
                    />
                    <Grid
                        item xs={12} sm
                        style={{ marginLeft: mediaSM ? 24 : undefined, marginTop : mediaSM ? undefined : 6 }}
                    >
                        <div style={{ color: greenColor }}>
                            {t('happinco_translations.20')}
                        </div>
                        <ul className="HappInCo-ul">
                            <li>{t('happinco_translations.21')}</li>
                            <li>{t('happinco_translations.22')}</li>
                            <li>{t('happinco_translations.23')}</li>
                        </ul>
                    </Grid>
                </Grid>
                {/* -------------------- STRUCTURES -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ padding: 24 }}
                >
                    <img
                        alt="Structures d’accompagnement ou d'insertion"
                        src={AccompagnementImg}
                        width={300}
                        height={200}
                        loading="lazy"
                    />
                    <Grid
                        item xs={12} sm
                        style={{ marginLeft: mediaSM ? 24 : undefined, marginTop : mediaSM ? undefined : 6 }}
                    >
                        <div style={{ color: blueColor }}>
                            {t('happinco_translations.24')}
                        </div>
                        <ul className="HappInCo-ul">
                            <li>{t('happinco_translations.25')}</li>
                            <li>{t('happinco_translations.26')}</li>
                            <li>{t('happinco_translations.27')}</li>
                        </ul>
                    </Grid>
                </Grid>
            </Layout>
        </>
    );
}
