import { Routes, Route, Navigate, useMatch } from 'react-router-dom';

// Components
import AppBar from 'components/AppBar';
import Footer from 'components/Footer';

// Views
import Home from 'views/Home';
import AboutUs from 'views/AboutUs';
import Expertise from 'views/Expertise';
import Solutions from 'views/Solutions';
import Contact from 'views/Contact';
import LegalNotice from 'views/LegalNotice';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- APP ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function App() {

    const matchHome = useMatch('/home');

    return (
        <>
            <AppBar />
            <div
                id="App"
                style={{ marginTop: matchHome ? 0 : undefined }}
            >
                <Routes>
                    <Route index path="home" element={<Home />} />
                    <Route path="about-us" element={<AboutUs />} />
                    <Route path="expertise" element={<Expertise />} />
                    <Route path="solutions" element={<Solutions />} />
                    <Route index path="contact" element={<Contact />} />
                    <Route index path="legal-notice" element={<LegalNotice />} />
                    <Route path="*" element={<Navigate to="/home" />} />
                </Routes>
            </div>
            <Footer />
        </>
    );
}
