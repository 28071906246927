import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// CSS
import './AboutUs.css';

// MUI
import { Grid, Button } from '@mui/material';
import { OpenInNew as OpenIcon } from '@mui/icons-material';

// Services
import { API_URL } from 'services/constants';

// Components
import HeadAttributes from 'components/HeadAttributes';
import Layout from 'components/Layout';

// Assets
import IncubatorImg from 'assets/images/about-us/incubateur.jpg';
import SoliversLogo from 'assets/images/partners/solivers.svg';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ABOUT US ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AboutUs() {

    const { t } = useTranslation();

    useEffect(() => {
        if (window.scrollY > 0) {
            window.scroll(0, 0);
        }
    }, []);

    return (
        <>
            <HeadAttributes
                title={t('about_us')}
                canonical={API_URL + 'about-us'}
            />
            <Layout>
                {/* -------------------- TITLE -------------------- */}
                <div className="title-container">
                    <h1>{t('about_us_translations.1')}</h1>
                </div>
                {/* -------------------- SUBTITLE -------------------- */}
                <div style={{ padding: '0px 24px' }}>
                    <b>{t('about_us_translations.2')}</b>
                </div>
                {/* -------------------- INCUBATOR -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ padding: 12 }}
                >
                    <Grid
                        item xs={12} md={6}
                        container justifyContent="center"
                        style={{ padding: 12 }}
                    >
                        <img
                            alt="Incubateur"
                            src={IncubatorImg}
                            width={500}
                            height={385}
                            loading="eager"
                            className="AboutUs-image"
                        />
                    </Grid>
                    <Grid
                        item xs={12} md={6}
                        style={{ padding: 12 }}
                    >
                        <img
                            alt="Logo de Solivers"
                            src={SoliversLogo}
                            width={180}
                            height={40}
                            loading="eager"
                        />
                        <h3 style={{ color: '#c1b299' }}>
                            {t('about_us_translations.3').toUpperCase()}
                        </h3>
                    </Grid>
                </Grid>
                <div style={{ padding: '0px 24px', textAlign: 'justify' }}>
                    {t('about_us_translations.4')}
                </div>
                <div style={{ padding: '0px 24px', marginTop: 12 }}>
                    <Button
                        href="https://www.solivers.eu/projets"
                        target="_blank"
                        rel="noreferrer"
                        endIcon={<OpenIcon />}
                        variant="contained" color="primary"
                    >
                        {t('about_us_translations.5')}
                    </Button>
                </div>
                {/* -------------------- TITLE -------------------- */}
                <div className="title-container">
                    <h1>{t('about_us_translations.6')}</h1>
                </div>
                {/* -------------------- SUBTITLE -------------------- */}
                <div style={{ padding: '0px 24px', textAlign: 'justify' }}>
                    <b>{t('about_us_translations.7')}</b>
                </div>
            </Layout>
            {/* -------------------- QUALITY -------------------- */}
            <div className="AboutUs-quality">
                <div
                    className="AboutUs-quality-grid"
                    style={{ gridTemplateRows: '200px' }}
                >
                    <div className="AboutUs-quality-item">
                        <div
                            className="AboutUs-item AboutUs-MinistereEnseignement"
                        />
                    </div>
                    <div className="AboutUs-quality-item">
                        <div
                            className="AboutUs-item AboutUs-FranceCompetence"
                        />
                    </div>
                    <div className="AboutUs-quality-item">
                        <div
                            className="AboutUs-item AboutUs-Qualiopi"
                        />
                    </div>
                    <div className="AboutUs-quality-item">
                        <div
                            className="AboutUs-item AboutUs-ECVET"
                        />
                    </div>
                    <div className="AboutUs-quality-item">
                        <div
                            className="AboutUs-item AboutUs-EQF"
                        />
                    </div>
                </div>
            </div>
            <Layout>
                {/* -------------------- TITLE -------------------- */}
                <div className="title-container">
                    <h1>{t('about_us_translations.8')}</h1>
                </div>
                {/* -------------------- SUBTITLE -------------------- */}
                <div style={{ padding: '0px 24px', textAlign: 'justify' }}>
                    <b>{t('about_us_translations.9')}</b>
                </div>
                <Grid
                    container alignItems="center" justifyContent="space-evenly"
                    style={{ padding: 12 }}
                >
                    <Grid
                        item xs="auto"
                        style={{ padding: 12 }}
                    >
                        <div
                            className="AboutUs-partner AboutUs-MFR"
                        />
                    </Grid>
                    <Grid
                        item xs="auto"
                        style={{ padding: 12 }}
                    >
                        <div
                            className="AboutUs-partner AboutUs-RegieDesEcrivains"
                        />
                    </Grid>
                    <Grid
                        item xs="auto"
                        style={{ padding: 12 }}
                    >
                        <div
                            className="AboutUs-partner AboutUs-Solivers"
                        />
                    </Grid>
                </Grid>
            </Layout>
        </>
    );
}
